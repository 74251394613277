import axios from 'axios';
import { useCallback, useEffect } from 'react';

import {
  isGuest,
  isOrgMember,
  useIsUserLoaded,
  useUser,
} from '../components/UserContext';
import { useVenueSlug } from '../components/Venue/VenueProvider';
import { apiService } from '../services/api-service';
import { RoleUtils, type User } from '../types/user';
import { type Venue } from '../types/venue';
import { assertExhaustive } from '../utils/common';
import { getToken } from '../utils/getToken';
import { clearToken } from '../utils/token';
import { useLiveCallback } from './useLiveCallback';

type CustomTargetFunc = (u: User | null) => string;
type RedirectTarget = string | CustomTargetFunc;
type Redirect = (rules: RedirectRule[]) => Promise<boolean>;
type ExecRedirect = (user: User, rules: RedirectRule[]) => Promise<boolean>;

export const REDIRECT_CUSTOM_TARGET_MAP = {
  organizerVenue: (u: User | null): string => {
    if (!u) return '';
    return `/venue/${u.organizer?.venueName || u.organizer?.venueId}`;
  },
  noop: (_u: User | null): string => {
    return '';
  },
};

type RedirectUserKind =
  | 'noUser' // user not found in the context, ususally represents non-login state
  | 'admin' // someone whose role is admin
  | 'organizer' // organizer, someone who is a member of one organization
  | 'venueActivated' // anyone who has venue activated, who can be either admin/organizer/host
  | 'guest' // guest user, usually represents audience w/o an persistent account
  | 'final'; // if none of the kinds are matched, use this rule

export type RedirectRule = {
  kind: RedirectUserKind;
  target: RedirectTarget;
  clearToken?: boolean;
};

export const getUserFromToken = async (): Promise<User | null> => {
  const token = getToken();

  if (token) {
    const resp = await apiService.auth.verify();
    return resp.data.user;
  }

  return null;
};

export const useExecUserRedirect = (): ExecRedirect => {
  return useLiveCallback(
    async (user: User, rules: RedirectRule[]): Promise<boolean> => {
      let u: User | null = null;
      let redirectTo: RedirectTarget | undefined;
      try {
        u = user.id ? user : await getUserFromToken();
        for (const rule of rules) {
          switch (rule.kind) {
            case 'noUser':
              if (!u) redirectTo = rule.target;
              break;
            case 'admin':
              if (u && RoleUtils.isAdmin(u)) redirectTo = rule.target;
              break;
            case 'organizer':
              if (u && isOrgMember(u)) redirectTo = rule.target;
              break;
            case 'venueActivated':
              if (u?.venueActivated) redirectTo = rule.target;
              break;
            case 'guest':
              if (u && isGuest(u)) {
                if (rule.clearToken) {
                  clearToken();
                }
                redirectTo = rule.target;
              }
              break;
            case 'final':
              if (u) redirectTo = rule.target;
              break;
            default:
              assertExhaustive(rule.kind);
              break;
          }
          if (redirectTo) break;
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 401 || err.response?.status === 404) {
            clearToken();
            // If we're already at /login, there is no reason to redirect again
            // and forcefully drop the `redirect-to` searchParam. This could
            // happen if other code purposefully redirects to /login, or if this
            // hook is executing concurrently on the page.
            if (window.location.pathname !== '/login') {
              redirectTo = '/login';
            }
          } else {
            throw err;
          }
        } else {
          throw err;
        }
      }
      if (redirectTo) {
        const target =
          typeof redirectTo === 'function' ? redirectTo(u) : redirectTo;
        if (target) {
          window.location.replace(target);
          return true;
        }
        return false;
      }
      return false;
    }
  );
};

/**
 * The redirect function accepts a list of rules, and evaluates them one by one.
 * The first matched rule will be executed.
 * @returns
 */
export const useUserRedirect = (): Redirect => {
  const user = useUser();
  const execRedirect = useExecUserRedirect();
  return useCallback(
    async (rules: RedirectRule[]): Promise<boolean> => {
      return execRedirect(user, rules);
    },
    [execRedirect, user]
  );
};

export const useEnsureHostPageRedirect = (user: User, venue: Venue): void => {
  const redirect = useUserRedirect();
  const isUserLoaded = useIsUserLoaded();
  const venueSlug = useVenueSlug();
  useEffect(() => {
    if (!isUserLoaded || !venue.uid || !user.id) return;
    const rules: RedirectRule[] = [
      { kind: 'guest', target: '/login' },
      {
        kind: 'venueActivated',
        target:
          venue.uid === user.id
            ? REDIRECT_CUSTOM_TARGET_MAP.noop
            : `/venue/${venueSlug}`,
      },
      { kind: 'final', target: `/venue/${venueSlug}` },
    ];

    redirect(rules);
  }, [redirect, user.id, isUserLoaded, venue.uid, venueSlug]);
};
