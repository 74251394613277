import { Outlet } from '@remix-run/react';
import { useEffect, useState } from 'react';

import { type RedirectRule, useUserRedirect } from '../../hooks/useRedirection';
import { GlobalLoading } from '../GlobalLoading';
import { useIsUserLoaded, useUser } from '../UserContext';
import { buildSearchParamsWithRedirectTo } from './hooks';

/**
 * Requires a user to be authenticated, but by default does not permit access to
 * "guest" users.
 */
export function UserAccess(props: {
  children?: JSX.Element;
  allowGuests?: boolean;
}): JSX.Element {
  useUser({ init: true });
  const execRules = useUserRedirect();
  const isUserLoaded = useIsUserLoaded();
  const [ready, setReady] = useState(false);

  const target = `/login?${buildSearchParamsWithRedirectTo(
    window.location.href
  )}`;

  useEffect(() => {
    if (!isUserLoaded) return;
    const run = async () => {
      const rules: RedirectRule[] = [{ kind: 'noUser', target }];

      if (!props.allowGuests) {
        rules.push({ kind: 'guest', target });
      }

      const willRedirect = await execRules(rules);
      if (willRedirect) return;
      setReady(true);
    };
    run();
  }, [isUserLoaded, execRules, target, props.allowGuests]);

  if (!ready) {
    return <GlobalLoading debug='user-access' />;
  }

  return props.children ? props.children : <Outlet />;
}
